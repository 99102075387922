@use '@angular/material' as mat;

@include mat.core();

$my-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$violet-palette,  
  ),
));

html {
  // Emit theme-dependent styles for common features used across multiple components.
  @include mat.core-theme($my-theme);

  // Emit styles for MatButton based on `$my-theme`.
  @include mat.button-theme($my-theme);

  // Include the theme mixins for other components you use here.
}
