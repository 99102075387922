/* stylelint-disable */
:root {
  --primary-color: #043667;
  --secondary-color: #4a6fc3;
  --secondary-blue: #008ce3;
  --third-color: #ffa03a;
  --blue-comparison: #4472c4;
  --blue: #778dc4;
  --white: #fff;
  --black: #231f20;
  --background-color: #f5f5f5;
  --grey: #231f20;
  --secondary-grey: #96a3b5;
  --light-grey: #dcdde0;
  --red: #ee5832;
  --secondary-red: #e48e8e;
  --subtitle: #7d8186;
  --green: #3db943;
  --pink: #ff5587;
  --rating: #ffcc63;
  --note-background: #ecf8f8;
  --orange: #ee5832;
  --yellow: #feba3e;
  --violet: #b662e3;
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.12;
}

body {
  background-color: #edf1f7;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0;
}

.form-control {
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.6875rem 1rem;
  border-radius: 0.25rem;
  background-color: #f7f9fc;
  border-color: #e4e9f2;
  color: #222b45;
  transition-duration: 0.15s;
  transition-property: border, background-color, color, box-shadow;
  transition-timing-function: ease-in;
  border-style: solid;
  border-width: 1px;
  font-family: Open Sans, sans-serif;
  appearance: none;
}

.toast-error {
  color: #ff3d71;
}

.input-error {
  display: block;
  color: var(--mat-form-field-error-text-color);
  text-align: left;
  font-family: var(--mat-form-field-container-text-font);
  line-height: var(--mat-form-field-container-text-line-height);
  font-size: var(--mat-form-field-container-text-size);
  letter-spacing: var(--mat-form-field-container-text-tracking);
  font-weight: var(--mat-form-field-container-text-weight);
}

.form-control.ng-invalid.ng-touched {
  border: 1px solid var(--mdc-outlined-text-field-error-focus-outline-color);
}

.mdc-checkbox + label {
  font-family: Open Sans, sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #222b45;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: ease-in;
}

mat-card-header {
  border-bottom: 1px solid #e4e9f2;
}

.login-card-form-container--full {
  margin: 0;
  height: calc(100vh - 5rem);
  background-color: #fff;
  color: #222b45;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.login-logo {
  margin: -6rem auto 0;
  text-align: center;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #8f9bb3;
  font-family: Open Sans, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}

.btn.btn--full-width {
  color: var(--white) !important;
  width: 100%;
  min-height: 3rem;
  padding: 0.8125rem 1.125rem !important;
  background-color: var(--primary-color);
  margin: 2rem 1rem 0;
  color: var(--white);
  border-style: solid;
  border-width: 0.0625rem;
  text-transform: uppercase;
  transition-duration: 0.15s;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: ease-in;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.8125rem 1.125rem;
  appearance: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  border-radius: 0 !important;
}

mat-spinner {
  margin: 0 auto;
}

.cdk-overlay-pane .mdc-menu-surface {
  background-color: var(--white) !important;
  min-width: 185px !important;
}

.cdk-overlay-connected-position-bounding-box {
  /* top: 64px !important; */
}

#mat-menu-panel-0 {
  top: 16px !important;
  left: 8px;
}

.header-info mat-icon,
.cdk-overlay-container mat-icon,
mat-expansion-panel-header mat-icon {
  color: #8f9bb3 !important;
}

.mat-drawer-container {
  height: calc(100vh - 5rem);
}

.mat-drawer-inner-container {
  background-color: var(--white);
}

.mat-mdc-menu-item .mat-icon {
  margin-right: 0 !important;
}

mat-sidenav-container .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text,
.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item .mdc-list-item__primary-text,
mat-expansion-panel-header .mat-content {
  display: flex;
  align-content: center;
  font-family: Open Sans, sans-serif;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: #222b45;
}

mat-expansion-panel-header .mat-content {
  padding: 0 8px !important;
}

mat-expansion-panel,
mat-expansion-panel:focus {
  box-shadow: none !important;
  background: var(--white) !important;
}

mat-expansion-panel .mdc-list-item,
mat-expansion-panel mat-nav-list {
  padding: 0 !important;
}

.mat-sidenav-container .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text:hover,
.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item .mdc-list-item__primary-text:hover {
  color: #598bff;
}

mat-expansion-panel-header {
  max-height: 48px;
}

.mdc-list-item.mdc-list-item--with-one-line,
.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item,
mat-expansion-panel-header {
  border-bottom: 1px solid var(--light-grey);
}

mat-sidenav-container .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text .mat-icon,
mat-expansion-panel-header mat-icon {
  color: #8f9bb3 !important;
  margin-right: 0.5rem;
}

mat-sidenav {
  width: 250px;
}

.mat-toolbar.mat-toolbar-single-row {
  box-shadow: 0 0.5rem 1rem #2c33491a !important;
  position: relative;
  z-index: 10;
}

.mat-mdc-menu-content.mdc-list {
  padding: 0 !important;
}

.header-drawer-menu .mdc-list-item__primary-text {
  display: none !important;
}

.mat-drawer-backdrop {
  background: transparent !important;
}

.mat-toolbar,
.mat-drawer-container {
  background: var(--white) !important;
}

.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item {
  border-bottom: none !important;
}

mat-sidenav-content {
  background: #edf1f7;
}

mat-card {
  height: 100%;
  width: 100%;
}

.home-container .mat-mdc-card-title {
  font-size: 2rem;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 2.5rem;
}

.calendar {
  margin-left: 2rem;

  & .mat-mdc-form-field-subscript-wrapper {
    font-size: 10px;
    font-family: Open Sans, sans-serif;
  }

  & mat-form-field {
    font-size: 10px;
  }

  & mat-mdc-text-field-wrapper.mdc-text-field {
    height: 36px;
  }

  & .mdc-text-field--outlined {
    height: 36px;
  }

  & mat-mdc-text-field-wrapper.mat-mdc-form-field-infix {
    height: 36px;
  }

  & .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    min-height: 36px;
    padding: 0;
  }

  & .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 36px;
    width: 36px;
  }

  & .mdc-icon-button svg,
  .mdc-icon-button img {
    height: 20px;
    width: 20px;
  }

  & .mat-mdc-form-field-icon-suffix {
    height: 36px;
    width: 36px;
  }

  & mat-datepicker-toggle {
    height: 36px;
    width: 36px;
    display: block;
  }

  & .mat-mdc-icon-button.mat-mdc-button-base {
    height: 36px;
    width: 36px;
  }

  & .mat-mdc-icon-button svg {
    position: absolute;
    top: 10px;
    right: 7px;
  }

  & .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 17px;
  }

  & .mdc-floating-label--float-above {
    top: 30px !important;
  }

  & .mat-date-range-input {
    margin-top: 10px;
  }
}

.report-buttons-download {
  width: 100%;
  text-align: right;
}

.report-tools {
  display: flex;
  width: 100%;
  margin: 1rem 0 1.5rem;
  border-bottom: 1px solid #e4e9f2;
}

.report-buttons-download {
  align-items: end;
  justify-content: flex-end;
}

.ag-header-cell-text {
  font-weight: bold;
}

.text {
  color: #222b45;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.dialog-header {
  display: flex;
  align-items: start;
  border-top: 1px solid #e4e9f2;
}

.dialog-header-text {
  width: 100%;
}

.ag-root-wrapper {
  border-radius: 0;
}

.ag-theme-quartz.show-borders .ag-row {
  border-bottom: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
}

.ag-theme-quartz .ag-row {
  border-bottom: none;
}

.ag-theme-quartz.center-align .ag-row {
  text-align: center;
}

.transactions .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-radius: 3px;
  height: 0.25rem;
  background-color: #36f;
}

.mdc-tab__text-label {
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase;
}

.mdc-dialog__container .mat-mdc-text-field-wrapper {
  padding-top: 8px !important;
}

/* .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-infix {
  padding-top: 0px !important;
} */
